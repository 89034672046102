.divFormMembers{
    margin: 0 ;
    padding: 20px;
    border-radius: 5px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  div > label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  input, select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
  }
  
  /* button {
    background-color: #ff0000;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: red;
  } */
  