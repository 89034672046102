/* Style pour le conteneur du planning */
#planning {
   font-family: Arial, sans-serif;
 }
 
 /* Style pour le titre du planning */
 #planning h2 {
   font-size: 24px;
   margin-bottom: 10px;
   color: black;
 }
 
 /* Style pour le tableau des événements */
 #planning table {
   width: 100%;
   border-collapse: collapse;
   margin-top: 20px;
 }
 
 /* Style de l'en-tête du tableau */
 #planning thead {
   background-color: black; /* Rouge */
   color: #fff;
 }
 
 #planning th {
   padding: 10px;
   text-align: left;
 }
 
 /* Style des lignes impaires du corps du tableau */
 #planning tbody tr:nth-child(odd) {
   background-color: #f2f2f2;
 }
 
 /* Style des cellules du tableau */
 #planning td {
   padding: 10px;
   border: 1px solid #ddd;
 }
 
 /* Style pour les boutons "Modifier" et "Supprimer" */
  .ajouterEvenement, .edit-button, .delete-button {
   padding: 5px 10px;
   margin-right: 5px;
   cursor: pointer;
   border: none;
   border-radius: 5px;
   font-size: 14px;
   background-color: #ff4136; /* Rouge */
   color: #fff;
 }
 
 
 .ajouterEvenement:hover, .delete-button:hover, .edit-button:hover {
   background-color: #d30c00; /* Rouge plus foncé au survol */
 }

 /* Style pour afficher le text en noi et fond blanc transparent
 .relative.group:hover .text-lg.font-semibold.text-white.mb-1,
 .relative.group:hover ..text-white {
   color: black;
   background-color: rgba(255, 255, 255, 0.8);
   padding: 5px;
   border-radius: 5px;
 } */
