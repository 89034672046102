
/* Style the header*/
    header {
        display: flex;
        /* background-color: #666; */
        text-align: center;
        color: white;
    }



/*     #logo{
    align-self: center;
    padding: 1%;
    } */

/* Style pour la liste à plat */
    ul.menu {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-right: 30px;
    }

    ul.menu li {
        margin-right: 40px;
        display: inline;
    }

    ul.menu li:last-child {
        margin-right: 0px;
    }

    ul.menu li:first-child {
        margin-left: 25px;
    }

    /* Style the navigation menu */
    nav {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: flex;
        justify-content: end;
    }

    /* Style the list inside the menu */
    nav ul {
        list-style-type: none;
        padding: 0;
        white-space: nowrap;

    }

    a.list{
        z-index: 1; /* Assure que le texte est en premier plan */
        color: black;
    }

    a.list:hover {
        color: #5c5c5c;
    }


    .canvas-container{
    z-index: 1;
    margin: 0;
    height: 150px;
    clip-path: polygon(0 0, 100% 0, 100% 61%, 0 100%);
    }

    canvas{
    width: 100%;
    height: 500px;
    }

    .menu{
    z-index: 2;
    position: absolute;
    top: 40px;
    }


    .buttonNone {
        z-index: 1; /* Assure que le texte est en premier plan */
        color: black;
        font-weight: bold;
        background: none;
        border: none;
        font-family: 'Segoe UI';
        font-size: 16px;
        /* Ajoutez d'autres styles nécessaires pour personnaliser le bouton */
      }
    .login{
    display: inline-block;
    background-color: rgba(211, 211, 211, 0.3);
    border-radius: 50px;
    padding-bottom: 5px;
    padding-top: 2px;
    padding-left: 20px;
    padding-right: 20px;
}

.title {

    z-index: 1;

}
      
      