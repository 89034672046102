/* Style pour le tableau */
#lecomite table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  /* Style de l'en-tête de la table */
  #lecomite thead {
    background-color: black; /* Rouge */
    color: #fff;
  }
  
  #lecomite th {
    padding: 10px;
    text-align: left;
  }
  
  /* Style des lignes impaires du corps du tableau */
  #lecomite tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  /* Style des cellules du tableau */
  #lecomite td {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  /* Style pour le titre */
  #lecomite h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: black; 
  }
  
  /* Style pour le conteneur de texte */
  #lecomite .texte {
    font-family: Arial, sans-serif;
    color: #333; /* Couleur de texte par défaut */
  }
  
  /* Style pour les boutons "Modifier" et "Supprimer" */
  .modifier-button, .supprimer-button {
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .modifier-button {
    background-color: #ff4136; /* Rouge */
    color: #fff;
  }
  
  .modifier-button:hover {
    background-color: #d30c00; /* Rouge plus foncé au survol */
  }
  
  .supprimer-button {
    background-color: #ff4136; /* Rouge */
    color: #fff;
  }
  
  .supprimer-button:hover {
    background-color: #d30c00; /* Rouge plus foncé au survol */
  }
  
  /* Style pour le bouton "Créer un membre de comité" */
  #ajouterMembre button {
    background-color: #ff4136; /* Rouge */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  #ajouterMembre button:hover {
    background-color: #d30c00; /* Rouge plus foncé au survol */
  }
  