.formulaireLogin{
    width: 300px;
    color: black;
    border: #ccc;
    border-radius: 50px;
    background-color: #ccc;
    padding: 25px;
    margin-bottom: 5%;
    margin-top: 2.8%;
    margin-left: 30px;
    position: absolute;
    top: 250px;
    left: 650px;
      
}



form h2{
    margin: 0;
    text-align: center;
}

#columnWrapper{
    display: flex;
}

.form-group > input{
    width: 290px;
    height: 25px;
    margin-top: 5px;
    margin-bottom: 20px;
}


