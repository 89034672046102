



.card{
    /* background: #2f3542; */
    background: none;
    width : calc(29%);
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: #fff; */
    color: black;
    cursor: pointer;
}

.card-icon{
    font-size: 30px;
    background: #ff6348;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px !important;
    border-radius: 50%;
    transition: 0.3s linear;
    color: #fff;
}

.card:hover .card-icon{
    background: none;
    color: #ff6348;
    transform: scale(1.4);
}

.card a{
    margin-top: 10px ;
    font-weight: 300 ;
    max-height: 0;
    opacity: 0;
    transition: 0.3s linear;
}

.card:hover a{
    max-height: 40px;
    opacity: 1;
}

